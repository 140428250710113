.testimonial_container{
    width: 300px;
    /* width: 60%; */
    /* min-width: 280px; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 40px;
}
.test_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 5%;
    padding: 0 5%;
}
.test_title p{
    font-family: var(--f2);
    font-size: var(--s2);
    font-weight: var(--w3);
    color: var(--white);
}
.ratings{
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.rating{
    width: 20%;
}
.rating img{
    height: 50%;
    object-fit: contain;
    object-position: bottom;
}
.test_content{
    height: 200px;
    background-color: var(--gray);
    padding: 5%;
    border-radius: 15px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    position: relative;
}
.test_content p{
    font-family: var(--f2);
    font-size: .85rem;
    font-weight: var(--w1);
    color: var(--dark_gray);  
    text-align: justify;
    margin-bottom: 5%; 
}
.test_content h4{
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    color: var(--black);  
}
.testimonial_dot{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5%;
}