.gradeBtn{
    height: auto;
    background-color: #161616;
    color: #ffffff;
    padding: .5rem 1.5rem;
    position: relative;
    text-decoration: none; 
    /* transition: all 1s ease;    */
    /* border: .5px solid var(--peach); */
}
.gradeBtn::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px; 
    padding: 1%; 
    /* background:linear-gradient(180deg,rgba(255,94,82,1)0%, #161616 10%, #161616 90%, rgba(255,94,82,1)100%); */
    /* background:linear-gradient(180deg,rgba(255,94,82,1)0%,rgba(255,94,82,.25)10%, rgba(255,94,82,.25)90%, rgba(255,94,82,1)100% , rgba(255,94,82,1)100%); */
    background:linear-gradient(270deg,rgba(255,94,82,.1)0%,rgba(255,94,82,1)45%, rgba(255,94,82,1)65%, rgba(255,94,82,.1)100%);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
}
.gradeBtn span{
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w2);
}
.gradeBtn img{
    height: .7rem;
    object-fit: contain;
    object-position: center;
    margin-left: 1.5rem;
}
.gradText{
    margin: 0 2rem;
}
.forBtn::before{
    /* padding: 1.5%;  */
}
.forBtn:active::before{
    padding: 2px; 
    background:linear-gradient(180deg,rgba(255,94,82,1)0%, rgba(255,94,82,1)100%);
}