.nav_container{
    width: 100%;
    padding-top: .5rem;
    position: fixed;
    z-index: 4;
    padding-bottom: .5rem;
    transition: 1s;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
}
.nav_main{
    width: 80%;
    margin-left: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.nav_logo{
    margin-right: auto;
    height: 2.5rem;
    aspect-ratio: 10/3.5;
}
.nav_logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.nav_web_menu{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 3rem;

    padding-right: 3rem;
}
.nav_web_menu a{
    font-size: var(--s1);
    font-family: var(--f2);
    font-weight: var(--w1);
    text-decoration: none;
    color: var(--white);
}
.align_right{
    margin-left: auto;
}
.nav_what{
    display: flex;
    justify-content: center;
    height: 4rem;
    aspect-ratio: 1/1;
}
.nav_what img{
    width: 80%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

/* ==================================== Media Query<1000 */

@media screen and (max-width:1070px) {
    .nav_web_menu{
        gap: 1rem;
        padding-right: 1rem;
    }
}