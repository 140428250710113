.footer{
    /* width: 70%; */
    /* margin-left: 15%; */
    width: 100%;
    padding: 2rem 15%;
    background-color: var(--black); 
}
.footer footer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column; 
}
.footer h1{
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w2);
    color: var(--white);
    margin-bottom: 3%;  
}
.footer_div{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;  
    gap: 5%;
}
.footer_container{
    width: 35%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    position: relative;
}
.footerLogo{
    margin-bottom: 1rem;
}
.container_1{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
}
.container_1 h3{
    font-family: var(--f2);
    /* font-size: var(--s1); */
    font-size: 1.5rem;
    font-weight: var(--w3);
    color: var(--white);
    /* margin-bottom: 1rem;    */
}
.container_1 p{
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    color: var(--white);
    line-height: 20px;
    /* margin-bottom: 1rem;    */
}
.footer_menu{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 50%;
    padding-top: 2rem;
}
.footer_menu a{
    font-family: var(--f2);
    /* font-size: var(--s1); */
    font-size: 1.25rem;
    font-weight: var(--w3);
    color: var(--white);
    margin-bottom: 1.5rem; 
    text-wrap: nowrap;
    text-decoration: none;
}
.footer_width_social{
    padding-top: 2rem;
    width: 45%;
}
.footer_social{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 30%;

    /* position: absolute;
    bottom: -100%; */
}
.footer_social h3{
    font-family: var(--f2);
    /* font-size: var(--s1); */
    font-size: 1.5rem;
    font-weight: var(--w3);
    color: var(--white);
    margin-bottom: 10%;   
    text-wrap: nowrap;
}
.social_icons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
}
.icon{
    width: 18%;
}
.icon img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.footer_image_container{
    width: 100vw;
}
.footer_image{
    width: 100%;
    object-fit: contain;
    object-position: center;
}

@media screen and (max-width:768px) {
    .footer{
        padding: 2rem 5%;
    }
    .footer_div{
        flex-direction: column;
    }
    .footer_container{
        width: 100%;
    }
    .footer_menu{
        padding-left: 0;
    }
    .footer_menu h3{
        margin-bottom: .5rem; 
    }
    .footer_social{
        padding-left: 0;
    }
    .footer_social h3{
        margin-bottom: 5%;
    }
    .social_icons{
        width: 70%;
    }
}