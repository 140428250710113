/* .mainLoading{
    width: 100dvw;
    height: 100dvh;
    background-color: var(--black);
    position: fixed;
    z-index: 5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    top: 0;
}
.sloganContainer{
    position: relative;
    width: 50%;
    height: 8rem;
    display: flex;
}
.circle{
    width: 6.2rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: var(--peach);
}
.aniCircle{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -3rem;
    animation: circleMove 3s ease 3;
}
@keyframes circleMove {
    0%{
        right: -3rem;
    }
    50%{
        right: -6.25rem;
    }
    100%{
        right: -3rem;
    }
}

.slogantext{
    position: absolute;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}
.slogan{
    color: var(--white);
    font-size: var(--sizeLoad);
    font-family: var(--fontLoad);
    font-weight: var(--weightLoad);
    padding-right: 1rem;
    position: absolute;
    right: -100%;
    animation: move 3s ease 3;
}
@keyframes move {
    0%{
        right: -100%;
    }
    50%{
        right: 0;
    }
    100%{
        right: -100%;
    }
}
.slogan::before{
    content: "";
    font-size: var(--sizeLoad);
    font-family: var(--fontLoad);
    font-weight: var(--weightLoad);
    animation: sloganLoad 9s ease-in-out;
}
@keyframes sloganLoad {
    0%{
        content: "Define";        
    }
    50%{
        content: "Surpass";
    }
    90%{
        content: "Fly";
    }
    100%{
        content: "Fly";
    }
} */

.mainLoading{
    width: 100dvw;
    height: 100dvh;
    top:0px;
    background-color: var(--black);
    position: fixed;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sloganContainer{
    /* width: 100%; */
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
}
.circle{
    width: 6.2rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: var(--peach);

    position: absolute;
    right: -3.25rem;
    overflow: hidden;
}
.circleContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: moveTest 3s ease 3 forwards;
}
.slogantext{
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: moveTest 3s ease 3;

    position: relative;
}
@keyframes moveTest {
    0%{
        transform: translateX(-50%);
    }
    50%{
        transform: translateX(-0%);
    }
    100%{
        transform: translateX(-50%);
    }
}
.slogan{
    color: var(--white);
    font-size: var(--s5);
    font-family: var(--f4);
    font-weight: var(--w4);
    padding-right: 3.5rem;
    animation: move 3s ease 3;
}
@keyframes move {
    0%{
        transform: translateX(100%);
    }
    50%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(100%);
    }
}
.slogan::before{
    content: "";
    font-size: var(--s5);
    font-family: var(--f4);
    font-weight: var(--w1);
    animation: sloganLoad 9s ease-in-out;
}
@keyframes sloganLoad {
    0%{
        content: "Define";        
    }
    59%{
        content: "Surpass";
    }
    70%{
        content: "Fly";
    }
    100%{
        content: "Fly";
    }
}
/* .slogantext::after{
    content: '';
    width: 6.2rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: var(--peach);
    position: absolute;
    right: 0%;
} */

@media screen and (max-width:768px) {
    .slogan::before{
        font-size: var(--s4);
    }
    .circle{
        /* top: 0; */
        width: 3.5rem;
        right: -1.75rem;        
    }
    .slogantext{
        align-items: flex-start;
        margin-top: 5%;
    }
    .slogan{
        padding-right: 2.5rem;
    }
}











.loading2{
    width: 100dvw;
    height: 100dvh;
    background-color: var(--black);
    position: relative;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    overflow: hidden;
    /* animation: zmove 5s ease forwards; */
}
@keyframes zmove {
    90%{
        z-index: 5;
    }
    100%{
        z-index: 0;
    }
}
.hide{
    display: none;
}
.backCircle{
    width: 4rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: var(--white);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.circle2{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
}
.backAni{
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes backCircle {
    25%{
        width: 4rem;
    }
    50%{
        border-radius: 50%;
    }
    99%{
        opacity: 1;
    }
    100%{
        width: 110rem;
        border-radius: 0;
        opacity: 0;
        display: none;
    }
}
.cirAni2{
    /* animation: circle2 5s ease forwards; */
}
/* @keyframes circle2 {
    25%{
        width: 4rem;
        top: 50%;
    }
    50%{
        top: 50%;
    }
    100%{
        width: 25rem;
        top: 90%;
    }
} */
@keyframes circle2 {
    25%{
        width: 4rem;
        right: 50%;
    }
    /* 50%{
        right: 50%;
    } */
    100%{
        width: 25rem;
        transform: translate(50%,-50%);
        right: 30%;
    }
}
.newLandText{
    opacity: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    opacity: 1;

    /* animation: landText 5s ease forwards; */
}
.newLandText article{
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

}
.newLandText article header{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
@keyframes landText {
    0%{
        /* display: none; */
    }
    75%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.newLandText article h1{
    padding-left: clamp(3rem, calc(3rem + 5vw),10rem);
    width: 45%;
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w1);
    line-height: 80px;
    letter-spacing: -0.03em;
    text-align: left;
    text-wrap: wrap;
    color: var(--white);
}
.newLandText article p{
    padding-left: clamp(3rem, calc(3rem + 5vw),10rem);
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    line-height: 12px;
    letter-spacing: -.001rem;
    text-align: left;
    width: 45%;
    text-wrap: wrap;
    color: var(--white);
}
.home_land_btn{
    margin-left: clamp(3rem, calc(3rem + 5vw),10rem);
    margin-top: 1.5rem;
}
.home_land_btn a{
    text-decoration: none;
    color: var(--white);
    font-size: 1rem;
}
.circle2landing{
    width: 25rem !important;
    transform: translate(50%,-50%);
    right: 30%;
}
/* ================================================== */
@media screen and (max-width:768px) {
    /* .loading2{
        height: 100%;        
    } */
    .backCircle{
        width: 2rem;
        height: 2rem;
    }
    @keyframes backCircle {
        0%{
            width: 2rem;            
            height: 2rem;
        }
        50%{
            border-radius: 100%;
            /* width: 100dvw;            
            height: 100dvw; */
        }        
        99%{
            opacity: 1;
        }
        100%{
            width: 100dvh;
            height: 100dvh;
            border-radius: 0;
            opacity: 0;
            display: none;
        }
    }

    @keyframes circle2 {
        25%{
            width: 2.5rem;
            right: 50%;
        }
        /* 50%{
            right: 50%;
        } */
        100%{
            width: 15rem;
            transform: translate(50%,-50%);
            /* right: 30%; */
            right: 50%;
            top: 35%;
        }
    }
    .circle2landing{
        width: 15rem !important;
        transform: translate(50%,-50%);
        right: 50%;
        top: 35%;
    }
    .newLandText {
        justify-content: flex-end;
    }
    .newLandText article h1{
        /* padding-left: clamp(3rem, calc(3rem + 5vw),10rem); */
        padding: 0 2rem;
        width: 100%;
        line-height: 50px;
        text-align: center;
        margin-bottom: .5rem;
    }
    .newLandText article p{
        padding: 0 2rem; 
        width: 100%;       
        line-height: 12px;
        text-align: center;
        margin-bottom: 2rem;        
    }
    .btn{
        margin-top: 0 !important;
    }
    .home_land_btn{
        /* margin-left: clamp(3rem, calc(3rem + 5vw),10rem); */
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 7rem;
    }
    
}
/* ======================================================== */
.navDrop{
    width: 100%;
    height: 5rem;
    position: fixed;
    top: -100%;
    z-index: 0;

    animation: navDrop 2s ease forwards;
}
@keyframes navDrop {
    0%{
        z-index: 0;
    }
    100%{
        top: 0%;
        z-index: 6;
    }
}

.follower {
    pointer-events: none;
    z-index: 12; 
  }
.big{
    position: fixed;    
    padding: 70px 70px;
    border-radius: 70px;
    /* pointer-events: none; Ensures the follower doesn't interfere with mouse events */
    pointer-events: none;
    z-index: 5;
    transform: translate(-50%,-50%);
}
.small{
    position: fixed;    
    padding: 8px 8px;
    border-radius: 10px;
    /* pointer-events: none; Ensures the follower doesn't interfere with mouse events */
    pointer-events: none;
    transform: translate(-50%,-50%);
}
.followerDifference{
    mix-blend-mode: difference;
}
.followerOther{
    mix-blend-mode: exclusion;
}
.peach{
    background-color: var(--peach);
}
.white{
    background-color: var(--white);
}
.black{
    background-color: var(--black);
}
.blackMove{
    animation: moveBlack 2s ease forwards;
}
@keyframes moveBlack {
    0%{
        top: 0vh;
    }    
    100%{
        top: -7.5vh;
    }
}

/* ===================================================Slider */
.home_landing_slide_cont{
    width: 100%;
    height: 100%;
    display: block;    /* background-color: aqua; */
    /* overflow: hidden; */
}
.home_landing_slider{
    width: auto;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    animation: homeDes 8s ease infinite;
}
.home_landing_slide{
    height: 100%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home_landing_slide img{
    height: 80%;
    object-fit: contain;
    object-position: center;
}
@keyframes homeDes {
    0%{
        transform: translateX(0);
    }
    33%{
        transform: translateX(-25rem);
    }
    66%{
        transform: translateX(-50rem);
    }
    100%{
        transform: translateX(0);
    }
}
.home_landing_slider_dis{
    display: none;
}
@media screen and (max-width:768px) {
    @keyframes homeDes {
        0%{
            transform: translateX(0);
        }
        33%{
            transform: translateX(-15rem);
        }
        66%{
            transform: translateX(-30rem);
        }
        100%{
            transform: translateX(0);
        }
    }

}