.project_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: auto;
    transition: 1s ease;
    position: relative;
    
}
.project_image{
    /* width: 35%; */
    /* width: 450px; */
    /* width: 300px; */
    width: clamp(300px, 40%, 450px);
    aspect-ratio: 1/1;
    margin-right: 5%;
    position: relative;
}
.project_image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.project_description{
    /* width: 35%; */
    width: clamp(300px, 40%, 450px);
    margin-right: 5%;
}
.project_description h3{
    font-family: var(--f1);
    font-size: var(--s3);
    font-weight: var(--w1);
    color: var(--white);
    margin-bottom: 10%;
}
.project_description p{
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    color: var(--white);
    line-height: 20px;
    margin-bottom: 10%;

    text-align: justify;
}
.project_btn_colour{
    background-color: var(--peach);
    color: var(--white);
    border: none;
    padding: .5rem 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    /* font-size: var(--sizeP1);
    font-family: var(--fontP1);
    font-weight: var(--weightH5); */
}
.project_btn_colour img{
    margin-left: 1rem;
}
.arrow_btn_container{
    width: 15%;
    position: absolute;
    right: -20%;
    top: 50%;
    transform: translateY(-25%);
}
.arrow_btn{
    width: 60%;
    aspect-ratio: 1/1;
    margin-bottom: 20%;
    /* background-color: var(--gray); */
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.arrow_btn img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.project_side{
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
    
    position: absolute;
    bottom: 0;
    left: clamp(-5rem, calc(-5rem - 1vw), -6rem);
}
.project_side h1{
    font-size: clamp(3rem, calc(3rem + 1vw), 4rem);
    font-family: var(--f3);
    font-weight: var(--w1);

    /* color: black;
    -webkit-text-fill-color: white; 
    -webkit-text-stroke-width: .6px;
    -webkit-text-stroke-color: black; */

    color: var(--white);
    -webkit-text-fill-color: var(--black); 
    -webkit-text-stroke-width: .6px;
    -webkit-text-stroke-color: var(--white);
}

@keyframes upProject {
    0%{
        transform: translateY(0);
        opacity: 1;
    }
    33%{
        transform: translateY(-200px);
        opacity: 0;
    }
    66%{
        transform: translateY(200px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes downProject {
    0%{
        transform: translateY(0);
        opacity: 1;
    }
    33%{
        transform: translateY(200px);
        opacity: 0;
    }
    66%{
        transform: translateY(-200px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

/* ======================================= */
@media screen and (max-width:1070px) {
    .project_container{
        flex-direction: column;
        align-items: center;
    }
    .project_description{
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .arrow_btn_container{
        top: 37.5%;
    }
}

@media screen and (max-width:768px) {
    .home_project{
        padding-bottom: 0;
    }
    .home_project_title{
        margin-bottom: 2rem;
    }
    .project_image{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 80%;
        margin-bottom: 2rem;
    }
    .project_image img{
        width: 85%;
        height: auto;
    }
    .project_side{        
        left: -1.5rem;
    }
    .arrow_btn_container{
        /* position: static; */
        margin-top: 2rem;
        width: 50%;
        top: 3rem;
        transform: translateY(-50%);
        /* bottom: 20%; */
        left: auto;
        right: 0%;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        flex-direction: row;
        position: absolute;
        /* transform: rotate(90deg); */
        
    }
    .arrow_btn{
        width: 25%;
        margin: 0;
        /* margin: 0 2rem; */
    }
    
}