.about_banner{
    width: 100vw;
    aspect-ratio: 120/32;
    margin-top: 2%;
}
.about_banner img{
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.about_wrapper{
    width: 70vw;
    margin-left: 15vw;
    padding-top: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.about_wrapper h2{
    font-family: var(--fontAbt);
    font-size: var(--sizeAbt);
    font-weight: var(--weightAbt);
    margin-bottom: 5%;
}
.about_wrapper p{
    font-family: var(--fontP1);
    font-size: var(--sizeP1);
    font-weight: var(--weightP1);
    margin-bottom: 5%;
}
.expert_container{
    width: 80%;
    margin-left: 10%;
    margin-bottom: 5%;
}
.about_expert_images{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.about_expert_container_individual{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5%;
}
.about_expert_container_image{
    width: 45%;
    aspect-ratio: 1/1;
    margin-bottom: 10%;
}
.about_expert_container_image img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.about_expert_container_individual h3{
    font-family: var(--fontH5);
    font-size: var(--sizeH5);
    font-weight: var(--weightH5);
}
.about_flow_image_container{
    width: 50%;
    aspect-ratio: 1/1;
    /* position: relative; */
    margin-left: auto;
    margin-right: auto;
}
.about_flow_image_container img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.about_approach_image_container{
    width: 15%;
    aspect-ratio: 1/1;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
}
.about_approach_image_container img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.portfolio{
    width: 100%;
    margin-top: 5%;
    margin-bottom: 15%;
}
.slider{
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
}
.about_container{
    width: 260px;
    margin-right: 50px;
}
.slide_about_image{
    width: 100%;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    margin-bottom: 15%;
}
.slide_about_image img{
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.slide_about_title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slide_about_title p{
    margin-right: 5%;
    margin-bottom: 0;
    font-family: var(--fontH5);
    font-size: var(--sizeH5);
    font-weight: var(--weightH5);
}
.about_share_image img{
    width: 80%;
    aspect-ratio: 1/1;
}
.about_join_statement{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
}