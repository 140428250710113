.contactWrapper{
    width: 100vw;
    margin-top: 8%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 10rem;
}
.contactForm{
    width: 100%;
    /* margin-left: 10%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem;
    margin-top: 5%;
    margin-bottom: 10%;
}
.contactForm figure{
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.contactForm figure img{
    width: 100%;
    object-fit: contain;
}
.contactForm figure p{
    width: 100%;
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    color: var(--white);
    text-align: center;
    margin-top: 1rem;
} 
.contactForm form{
    max-width: 350px;
    min-width: 350px;
    width: 30%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--white);
    padding: 1.5rem;
    margin-right: 5rem;
    border-radius: 30px;
}
.contactForm form h2{
    color: var(--black);
    font-family: var(--f1);
    font-size: 1.5rem;
    font-weight: var(--w3);   
    margin-bottom: 1rem;
}
.contactInputContainer{
    width: 100%;
    margin-bottom:.75rem;
}
.contactInputContainer input, .contactInputContainer select{
    width: 100%;
    border-radius: 50px;
    padding: .5rem 1rem;
    background-color: var(--gray);
    color: var(--black);
    font-family: var(--f2);
    font-size: .9rem;
    font-weight: var(--w1);
    border: 2px solid var(--white);
}
.contactInputContainer input::placeholder{
    color: var(--black);
    font-family: var(--f2);
    font-size: .9rem;
    font-weight: var(--w1);   
}
.contactSubmitContainer button{
    margin-left: auto;
    background-color: var(--black);
    border-radius: 5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
    margin-top: 1rem;
    padding: .5rem 1rem;
    border: none;
    color: var(--white);
    font-family: var(--f2);
    font-size: 1rem;
    font-weight: var(--w3);
}
.contactSection{
    width: 70%;
    margin-left: 15%;
    margin-top: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    color: var(--white);
}
.contactSection h1{
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w2);
    color: var(--white);
    margin-bottom: 3%;
}
.contactContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.contactContainer .col1{
    width: 25%;
}
.contactContainer .col1 .row{
    margin-bottom: 1rem;
}
.contactContainer .col1 h3{
    font-family: var(--f1);
    font-size: var(--s1);
    font-weight: var(--w3);
    color: var(--white);
    margin-bottom: .25rem;
}
.contactContainer .col1 p{
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    color: var(--white);
}
.contactContainer form{
    width: 30%;
    min-width: 250px;
    max-width: 250px;
}
.reviewInput{
    width: 100%;
}
.reviewInput input, .reviewInput textarea{
    width: 100%;
    padding: .25rem .5rem;
    margin-bottom: .25rem;
    font-family: var(--f2);
    font-size: .8;
    font-weight: var(--w1);
    color: var(--black);
    border-radius: .5rem;
}
.reviewSubmit{
    width: 100%;
    display: flex;
    justify-content: center;
}
.reviewSubmit button{
    width: 100%;
    background-color: #161616;
    color: #ffffff;
    padding: .5rem 1.5rem;
    position: relative;
    text-decoration: none;
    outline: none;
    border: none;
    margin-top: .5rem;
} 
.reviewSubmit button::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: .25%;
    /* background: linear-gradient(180deg, rgba(255, 94, 82, 1) 0%, #161616 10%, #161616 90%, rgba(255, 94, 82, 1) 100%); */
    /* background: linear-gradient(180deg, rgba(255, 94, 82, 1) 0%, rgba(255, 94, 82, .25) 10%, rgba(255, 94, 82, .25) 90%, rgba(255, 94, 82, 1) 100%, rgba(255, 94, 82, 1) 100%); */
    background: linear-gradient(270deg, rgba(255, 94, 82, .1) 0%, rgba(255, 94, 82, 1) 45%, rgba(255, 94, 82, 1) 65%, rgba(255, 94, 82, .1) 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}
@media screen and (max-width:768px) {
    .contactWrapper{
        margin-top: 2rem;
    }
    .contactForm{
        flex-direction: column;
        padding: 1rem;
    }
    .contactForm figure{
        width: 100%;
        margin-bottom: 5rem;
        /* display: none; */
    }
    .contactForm form{
        margin-right: 0;
    }
    .contactContainer{
        flex-direction: column;
    }
    .contactContainer .col1{
        width: 100%;
        margin-bottom: 1rem;
    }
}