.home_wrapper{
    width: 65%;
    margin-left: 17.5%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--black);
    z-index: 1;
}
.hide{
    display: none;
}
.bhokeContainer{
    width: 100%;
    height: 100%;
    position: absolute;    
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 0;
}
.bhoke{
    position: absolute;
    right: 0%;
    top: 25%;
    filter: blur(90px);
    /* opacity: .5; */
}
.bhokeWho{
    position: absolute;
    left: -10%;
    top: -70%;
    filter: blur(90px);
}
.bhoketest{
    position: absolute;
    left: 80%;
    top: 0%;
    filter: blur(90px);
}
.bhokeabout{
    position: absolute;
    left: 50%;
    top: 100%;
    filter: blur(90px);
}
@media screen and (max-width:768px){
    .home_wrapper{
        width: 90%;
        margin-left: 5%;
    }
}
/*===================================================== Landing */
.home_landing{
    width: 100%;
    min-height: calc(100dvh - 5rem);
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.landing_content{
    width: 50%;
    /* margin-right: 2%; */
}
.landing_content h1{
    font-family: var(--fontH1);
    font-size: var(--sizeH1);
    font-weight: var(--weightH1);
    line-height: 94%;
    
}
.landing_content h1 span{
    color: var(--peach);
}
.landing_content p{
    font-family: var(--fontP1);
    font-size: var(--sizeP1);
    font-weight: var(--weightP1);
}
.btn{
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w3);
    padding: .2rem 1.5rem;
    border-radius: 50px;
    margin-top: 1rem;

    background-color: var(--black);
    /* border: 2px solid var(--white); */
    color: var(--white);

    overflow: hidden;
    border-style: solid;
    border-width: 2px;
    border-image: linear-gradient(45deg, rgb(0,143,104), rgb(250,224,66)) 1;
}

.btn img{
    margin-left: .5rem;
    height: 50%;
    object-fit: contain;
    object-position: center;
}
.landing_btn_colour{
    background-color: var(--black);
    color: var(--white);
}
.landing_image{
    width: 55%;
    aspect-ratio: 1/1;
}
/* .landing_image img{
    width: 100%;
    height: 100%;
    object-fit: 100%;
    object-position: 100%;
} */
/*===================================================== Our Clients */
.home_clients{
    width: 100vw;
    min-height: 80dvh;
    height: auto;

    position: relative;
}
.home_arc_top_load{
    width: 100%;
    position: absolute;
    z-index: 0;
    height: 100px;
    overflow: hidden;

    /* left: -12.5%; */
}
.home_arc_top_load img{
    width: 100%;
    object-fit: cover;
    object-position: center;
    
}
.home_arc_top{
    width: 100%;
    position: absolute;
    top: -7.5vh;
    z-index: 0;
    height: 100px;
    overflow: hidden;

    /* left: -12.5%; */
}
.home_arc_top img{
    width: 100%;
    object-fit: cover;
    object-position: center;
    
}
.home_arc_bottom{
    height: 100px;
    width: 100%;
    position: absolute;
    z-index: 0;
    transform: rotate(180deg);
    overflow: hidden;
}
.home_arc_bottom img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.home_client_container{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    position: relative;

    /* background-color: var(--black); */
}
.home_client_container h1{
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w2);
    color: var(--white);

    margin-right: auto;
    margin-left: 15%;
    margin-top: 2rem;
}
.slide_container{
    margin: 2rem 0 5rem 0;
}
.Client_slider{
    /* margin-top: 1rem; */
}
.smaller{
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    position: absolute;
    bottom: -4rem;
    color: aliceblue;
}
/*===================================================== Who */
.home_who{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-direction: column; */
    margin-top: 10rem;
    /* padding-top: 7%; */
    padding-bottom: 7%;
    position: relative;
}
.home_who_contaier{
    width: 55%;
    margin-bottom: 2rem;
}
.home_who h1{
    margin-bottom: 5% ;
    
    font-family: var(--f1);
    font-size: var(--s3);
    font-weight: var(--w1);
    color: var(--white);
}
.home_who h2{
    margin-bottom: 2rem ;
    
    font-family: var(--f1);
    font-size: var(--s3);
    font-weight: var(--w1);
    line-height: 50px;
    color: var(--white);
}
.home_who p{
    /* width: 100%; */
    width: 100%;
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    color: var(--white);
}
.home_who_image{
    width: 32%;
}
.home_who_image img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
@media screen and (max-width:1070px){
    .home_who{
        flex-direction: column-reverse;
    }
    .home_who_contaier{
        width: 100%;
    }
    .home_who_image{
        width: 50%;
    }
}
@media screen and (max-width:768px){    
    .home_who{
        margin-top: 10rem;
        margin-bottom: 0;
    }
    .home_who_contaier{
        width: 90%;
        text-align: justify;
        margin-top: 2.5rem;
    }
    .home_who_contaier h2{
        line-height: 30px;
    }
    .home_who_image{
        width: 90%;
    }
}
/*===================================================== Expertise */
.home_expertise{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* margin-top: 5%; */
    /* margin: 5% 0; */
    margin-top: 5rem;
    padding: 5rem 0;
}
.home_expertise article{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.home_expertise article header{
    width: 100%;
}
.home_expertise .home_expertise_title{
    width: 100%;
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w2);
    color: var(--white);
    margin-bottom: 3rem;
}
.home_expertise_container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}
.expert_container_home{
    width: 28%;
    min-width: 175px;
    aspect-ratio: 1/1;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    position: relative;
    border-radius: 50%;

    flex-direction: column;

    justify-content: flex-end;
}
.expert_container_home h2{
    font-family: var(--f1);
    font-size: var(--s3);
    font-weight: var(--w1);
    color: var(--white);

    /* margin-bottom: 5%; */
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
}
.home_expert_points{
    height: 40%;
    width: 100%;
    /* position: absolute; */
    bottom: -5%;

    margin-bottom: 1%;
}
.home_expert_points p{
    text-align: center;
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    color: var(--white);
    
}
.home_expertise_des{
    width: 85%;
    margin-top: 3rem;
    text-align: center;
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    line-height: 20px;
    color: var(--white);
}
.peach1{
    background-color: var(--peach);
}
.peach2{
    background-color: var(--peach2);
}
.peach3{
    background-color: var(--peach3);
}

@media screen and (max-width:768px) {
    .home_expertise{
        margin-top: 0rem;
    }
    .home_expertise_container{
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .expert_container_home img{
        width: 100%;
    }
    .home_expertise_des{
        text-align: justify;
    }
}
/*===================================================== Scroller */
.service_scroll{
    margin: 5rem 0;
}
.service_scroll .slider{
    flex-direction: column;
}
.scroller{
    font-family: var(--f3);
    font-size: var(--s7);
    font-weight: var(--w1);
    color: var(--peach);
    line-height: 94%;
}
/*===================================================== Projects */
.home_project{
    width: 100%;
    /* margin: 5% 0; */
    padding-top: 10%;
    padding-bottom: 7%;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.home_project_title{
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w2);
    color: var(--white);
    margin-bottom: 5rem;
}
@media screen and (max-width:768px) {
    .home_project{
        padding-top: 15%;
    }
}
/*===================================================== Testimonial */
.home_testimonial{
    width: 100%;
    margin: 5rem 0 10rem 0;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;   
}
.testrow1{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.testimonial_keys{
    width: 20%;
    transform: translateY(-75%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.home__test_keys{
    width: 50px;
    aspect-ratio: 1/1;
    /* margin-bottom: 20%; */
    background-color: var(--gray);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.home__test_keys img{
    width: 50%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.home_testimonial_title{
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w2);
    color: var(--white);
    margin-bottom: 5rem;
}
.home_testimonial_main{
    width: 100%;
}
.home_testimonial_slider{
    width: auto;
    
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    transition: all 1s ease;
}

@media screen and (max-width:768px) {
    .testimonial_keys{
        width: 50%;
        justify-content: center;
    }
    .home__test_keys{
        margin-right: 1rem;
    }
}
/*===================================================== Work Style */
.home_layer2{
    width: 100vw;
    background-color: var(--black);   
    position: relative; 
}
.home_workstyle{
    width: 65%;
    /* margin-top: 5%; */
    margin: 0 17.5%;
    padding-top: 3rem;
    /* padding-bottom: 19.5%; */
    position: relative;
    /* padding: 2% 25%; */

    
    
}
.home_workstyle article{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
}
.home_workstyle article header{
    width: 100%;
}
.home_workstyle_title{
    width: 100%;
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w2);
    color: var(--white);
    margin-bottom: 3rem;   
}
.home_workstyle .paragraph{
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
    line-height: 20px;
    color: var(--white);
    margin-top: 3rem;
    margin-bottom: 3rem;   
    text-align: justify;
}
.workStyle_image{
    position: relative;
    width: 60%;
    aspect-ratio: 1/1;
    /* transform: rotate(12deg); */
    /* background-color: antiquewhite; */
}
.workStyle_image img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.home_brand_title{
    width: 100%;
    font-family: var(--f1);
    font-size: var(--s3);
    font-weight: var(--w2);
    color: var(--white);
    margin: 5rem 0 0 0; 
}

@media screen and (max-width:768px) {
    .home_workstyle{
        width: 80%;
        margin: 0 10%;
    }
    .workStyle_image{
        width: 100%;
    }
}
/*===================================================== Work Style */
.home_team{
    width: 65%;
    margin: 7rem 17.5% 2rem 17.5%;
    position: relative;
    /* padding: 2% 25%; */

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;  
    background-color: var(--black);       
}
.home_team h1{
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w2);
    color: var(--white);
    margin-bottom: 3%;   
}
.team_list{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 5%;
}
.team_container{
    width: 28%;
}
.team_container_top{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}
.team_title{
    position: absolute;
    /* bottom: 14%; */
    bottom: 1%;
    left: -8px;
    /* left: calc(-.35* var(--s2)); */
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
}
.team_title h2{
    font-size: var(--s2);
    font-family: var(--f3);
    font-weight: var(--w2);
    color: var(--peach);
}
.team_image{
    width: 80%;
    /* aspect-ratio: 3/4; */
}
.team_image img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.team_name{
    font-size: var(--s1);
    font-family: var(--f2);
    font-weight: var(--w1);
    color: var(--white);
    
    display: flex;
    justify-content: center;
    align-items: flex-start;

    margin-top: 2%;
}

@media screen and (max-width:768px) {
    .team_list{
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
    }
    .team_container{
        width: 80%;
        margin-bottom: 2rem;
    }
    .team_title{
        bottom: 2%;
        left: -7%;
    }
}
/*===================================================== Map  */
.home_map{
    width: 100%;
    height: 500px;
    z-index: 1;
    margin-top: 2rem;

}
.home_map h1{
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w2);
    color: var(--white);
    margin-bottom: 3%;  
}
.home_map iframe{
    width: 100%;
    height: 100%;
    /* border-radius: 50px; */
}
/*===================================================== Field */
.home_talk{
    width: 100%;
    /* margin: 15rem 0; */
    margin-top: 2rem;
    padding: 12rem 0;
    /* padding-bottom: 12%; */

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;      
}
.home_talk h1{
    font-family: var(--f1);
    font-size: var(--s4);
    font-weight: var(--w2);
    color: var(--white);
    margin-bottom: 3%;   
}
.home_talk_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.input_row{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 2% 0;
    flex-wrap: wrap;
}
.input_container{
    width: 20%;
    min-width: 180px;
    margin-right:2.5%;
    margin-left:2.5%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 50px;
    padding: .5rem 1rem; */
}
.gray{
    background-color: var(--gray);
}
.input_container label{
    width: 100%;
    cursor: pointer;
    text-wrap: nowrap;
}
.input_container label span{
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 50px;
    padding: .5rem 1rem;
    background-color: var(--black);
    color: var(--white);
    font-family: var(--f2);
    /* font-size: var(--s1); */
    font-size: 1rem;
    font-weight: var(--w1);
    border: 2px solid var(--white);
}
.input_container input[type="checkbox"]{
    display: none;
}
.input_container label input:checked~span{
    /* background-color: var(--dark_gray); */
    background-color: var(--peach);
    color: var(--black);
    font-family: var(--f2);
    font-size: 1rem;
    font-weight: var(--w1);
    border: 2px solid var(--peach);
}
.text{
    width: 25%;
}
.input_container input{
    width: 90%;
    padding: .5rem 1rem;
    color: var(--white);
    border: none;
    border-bottom: 2px solid var(--white);
    margin-bottom: 2rem;
    transition: .5s;

    font-family: var(--f2);
    font-size: 1rem;
    font-weight: var(--w1);
    background-color: var(--black);
}
.input_container input:focus{
    /* border: none; */
    padding-left: 0;
    outline: none;
}
.input_container input:focus::placeholder{
    color: transparent;
}
.input_container input::placeholder{
    color: var(--white);
    font-family: var(--f2);
    font-size: 1rem;
    font-weight: var(--w1);
    padding-bottom: 1rem;
    /* text-align: center; */
}
/* .input_container button{
    padding: 5% 25%;
    border: none;
    border-radius: 25px;    

    font-family: var(--f2);
    font-size: 1.25rem;
    font-weight: var(--w3);
    background-color: var(--peach);
    color: var(--white);
} */
/* .input_container button:active{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
} */
.input_container button{
    border: none;
}
.alert-container{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alertBg{
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .5;
    position: absolute;
}
.alert{
    width: 300px;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 1;
    z-index: 11;
    border-radius: 15px;    
    padding: 1rem;
}
.alert button{
    padding: 1% 5%;
    border: none;
    border-radius: 5px;    

    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w3);
    background-color: var(--peach);
    color: var(--white);
    margin-top: 1rem;
}
.alert .message{
    font-family: var(--f2);
    font-size: var(--s1);
    font-weight: var(--w1);
}
@media screen and (max-width:768px) {
    .home_talk{
        width: 100%;
        /* margin: 15rem 0; */
        margin-top: 5rem;
        padding: 5rem 0;
        /* padding-bottom: 12%; */
    
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;      
    }
    .input_row{
        flex-direction: column;
        margin: 0;
    }
    .input_container{
        width: 80%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.8rem;
    }
    .input_container label{
        width: 100%;
    }
    .input_container label span{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1rem; 
    }
    .input_container textarea{
        height: 100px;
    }
    .home_talk_container{
        margin-top: 2rem;
    }
}
/*===================================================== landing Image */
.landing_container{
    width: 100%;
    aspect-ratio: 1/1;
    position: relative;
    transition: ease;
    animation: levitate 10s infinite;
    /* margin-left: 1%; */
}
@keyframes levitate {
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(100px);
    }
    100%{
        transform: translateY(0px);
    }
}
.main{
    width: 100%;
    position: absolute;
    z-index: 1;
}
.main img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.one{
    width: 25%;
    position: absolute;
    top: 0;
    left: 52%;
    z-index: 0;
    transition: cubic-bezier(.6,-0.61,.63,1.6);
    /* animation: one_ani 15s linear infinite; */
    /* transform-style: preserve-3d; */
}
.one img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
@keyframes one_ani {
    0%{
        top: 0;
        left: 52%;
        z-index: 0;
    }
    25%{
        top: 15%;
        left: -15%;
        z-index: 1;
        transform: rotateY(90deg);
    }
    50%{
        top: 30%;
        left: 52%;
        z-index: 2;
        transform: rotateY(180deg);
    }
    75%{
        top: 15%;
        left: 92%;
        z-index: 0;
        transform: rotateY(270deg);
    }
    100%{
        top: 0;
        left: 52%;
        z-index: 0;
        transform: rotateY(360deg);
    }
}
.two{
    width: 11%;
    position: absolute;
    top: -2%;
    left: 33%;
    z-index: 0;
}
.two img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.three{
    width: 7%;
    position: absolute;
    top: -8%;
    left: 22%;
    z-index: 0;
}
.three img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.four{
    width: 12%;
    position: absolute;
    top: -13%;
    left: 61%;
    z-index: 2;
}
.four img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.five{
    width: 9%;
    position: absolute;
    top: 10.5%;
    left: 72.5%;
    z-index: 2;
}
.five img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.six{
    width: 8%;
    position: absolute;
    top: 4%;
    left: 82%;
    z-index: 0;
}
.six img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.seven{
    width: 8%;
    position: absolute;
    top: 13%;
    left: 95%;
    z-index: 2;
}
.seven img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.eight{
    width: 14%;
    position: absolute;
    top: 54%;
    left: 4%;
    z-index: 0;
}
.eight img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.nine{
    width: 10%;
    position: absolute;
    top: 69%;
    left: 29%;
    z-index: 2;
}
.nine img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.ten{
    width: 10%;
    position: absolute;
    top: 74%;
    left: 55%;
    z-index: 0;
}
.ten img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.eleven{
    width: 9%;
    position: absolute;
    top: 65.5%;
    left: 73%;
    z-index: 2;
}
.eleven img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.twelve{
    width: 23%;
    position: absolute;
    top: 25%;
    left: 30%;
    z-index: 2;
}
.twelve img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}