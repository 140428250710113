@import url('https://fonts.googleapis.com/css2?family=Anton&family=Oswald:wght@200..700&family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root{
  --white:#F7F7F7;
  /* --white:#161616; */
  --black:#161616;
  --peach:#FF574A;
  --gray:#E6E6E6;
  --dark_gray:#555555;
  
  --peach2:#FF796F;
  --peach3:#FFA7A0;

  --f1:"Oswald", sans-serif;
  --f2:"Poppins", sans-serif;;
  --f3:"Anton", sans-serif;
  --f4: "Montserrat", sans-serif;

  /* --s1:1rem; */
  --s2:1.5rem;
  /* --s3:3rem; */
  /* --s4:4rem; */
  --s5:4.8rem;
  --s6:8rem;
  --s7:10rem;

  --s1:clamp(.8rem, calc(.6rem + .5vw), 1rem);
  /* --s2:clamp(1rem, calc(.8rem + .6vw), 2rem); */
  --s3:clamp(1.2rem, calc(1.4rem + .6vw), 3rem);
  --s4:clamp(2rem, calc(1.5rem + 2.5vw), 4rem);
  /* --s1:.8rem; */

  --w1:400;
  --w2:500;
  --w3:700;
  --w4:300
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
}
em{
  font-style: normal;
}
#root{
  width: 100%;
  height: auto;
  overflow-x: hidden;
  background-color: #161616;
}